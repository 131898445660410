import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Conciergerie } from "../models/conciergerie.model";
import { User } from "../models/user.model";
import { ApiService, ConciergerieResponse, MeResponse } from "./api.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
  private conciergerie: BehaviorSubject<Conciergerie> = new BehaviorSubject<Conciergerie>(undefined);

  private conciergerie_id: string = localStorage.getItem('conciergerie_id');

  /**
   * Mise en cache de l'utilisateur et de la conciergerie avec mise à jour du cache quand l'utilisateur actualise la page
   * @param authService
   * @param apiService
   */
  constructor(private authService: AuthService, private apiService: ApiService) {
    if (this.authService.isLoggedIn()) {
      let userJson = localStorage.getItem('user');
      if (userJson) {
        let user = JSON.parse(userJson);
        if (user) {
          this.user.next(user);
        }
      }

      let conciergerieJson = localStorage.getItem('conciergerie');
      if (conciergerieJson) {
        let conciergerie = JSON.parse(conciergerieJson);
        if (conciergerie && conciergerie.id == this.conciergerie_id) {
          this.conciergerie.next(conciergerie);
        }
      }

      this.apiService.post('me', {}, (res: MeResponse) => {
        this.user.next(res.user);
        localStorage.setItem('user', JSON.stringify(res.user));
      });

      this.loadConciergerie();
    }
  }

  setUser(user: User) {
    this.user.next(user);
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser(): BehaviorSubject<User> {
    return this.user;
  }

  getConciergerieId() {
    return this.conciergerie_id;
  }

  getConciergerie(): BehaviorSubject<Conciergerie> {
    return this.conciergerie;
  }

  setConciergerie(conciergerie: Conciergerie): void {
    this.conciergerie_id = conciergerie.id;
    localStorage.setItem('conciergerie_id', conciergerie.id);
    localStorage.setItem('conciergerie', JSON.stringify(conciergerie));
    this.conciergerie.next(conciergerie);
  }

  loadConciergerie(callback: ((conciergerie: Conciergerie) => void) = null) {
    this.apiService.post('conciergerie', {
      conciergerie_id: this.conciergerie_id
    }, (res: ConciergerieResponse) => {
      this.conciergerie.next(res.conciergerie);
      localStorage.setItem('conciergerie', JSON.stringify(res.conciergerie));
      if (callback) {
        callback(res.conciergerie);
      }
    });
  }
}
