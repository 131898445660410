import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrl: string = '/';
  token: string = localStorage.getItem('api/token');

  constructor() { }

  isLoggedIn(): boolean {
    if (this.token) {
      try {
        let jwt = JSON.parse(atob(this.token.split('.')[1]));
        let date = Math.floor(Date.now() / 1000);
        return date < jwt.exp;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  setToken(token: string): void {
    this.token = token;
    localStorage.setItem('api/token', token);
  }

  logout(): void {
    this.token = null;
    localStorage.removeItem('user');
    localStorage.removeItem('menu');
    localStorage.removeItem('api/token');
    localStorage.removeItem('conciergerie');
    localStorage.removeItem('conciergerie_id');
    localStorage.removeItem('non_payment_reminder_timestamp');
  }
}
