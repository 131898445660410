import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Conciergerie } from "../models/conciergerie.model";
import { User } from "../models/user.model";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  base_url: string = environment.api.url;

  constructor(private http: HttpClient, private authService: AuthService, private router: Router) { }

  post(service: string, params: any, success: (res: any) => void = null, fail: (res: ErrorResponse) => void = null): void {
    let headers: any = {};
    if (this.authService.isLoggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authService.token;
    }

    if (!params) {
      params = {};
    }

    params.__url__ = location.toString();
    params.__source__ = 'web';
    params.__version__ = environment.version;

    if (!params['conciergerie_id'] && localStorage.getItem('conciergerie_id')) {
      params['conciergerie_id'] = localStorage.getItem('conciergerie_id');
    }

    this.http.post(this.getUrl(service), params, {
      headers: new HttpHeaders(headers)
    }).subscribe(
      (res: Response) => this.handleResponse(res, success, fail),
      (error: HttpErrorResponse) => this.handleResponse(error, success, fail)
    );
  }

  convertToFormData(data: any, formData: FormData, parentKey: string|null) {
    if(data === null || data === undefined) return null;

    formData = formData || new FormData();

    if (typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key =>
        this.convertToFormData(data[key], formData, (!parentKey ? key : (data[key] instanceof File ? parentKey : `${parentKey}[${key}]`)))
      );
    } else {
      formData.append(parentKey!, data);
    }

    return formData;
  }

  upload(service: string, params: any, files: any, progress: ((progress: number) => void)|null = null, success: ((res: any) => void)|null = null, fail: ((res: ErrorResponse) => void)|null = null): void {
    let formData = new FormData();

    if (params && !params['conciergerie_id'] && localStorage.getItem('conciergerie_id')) {
      params['conciergerie_id'] = localStorage.getItem('conciergerie_id');
    }

    this.convertToFormData(params, formData, null);

    if (files instanceof FileList) {
      for (let i = 0; i < files.length; i++) {
        formData.append('file' + i, files.item(i)!, files.item(i)!.name);
      }
    } else if (files instanceof File) {
      formData.append('files[]', files, files.name);
    } else if (files instanceof Blob) {
      formData.append('files[]', files, params['file']);
    }

    let headers: any = {};
    if (this.authService.isLoggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authService.token;
    }

    this.http.request(new HttpRequest('POST', this.base_url + '/' + service, formData, {
      reportProgress: true,
      headers: new HttpHeaders(headers),
    })).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          if (progress) progress(0);
          break;
        case HttpEventType.UploadProgress:
          const percentDone = Math.round(100 * event.loaded / event.total!);
          if (progress) progress(percentDone);
          break;
        case HttpEventType.Response:
          let res = event.body;

          if (res.success) {
            if (success) success(res);
          } else {
            if (fail) fail(res as ErrorResponse);
          }
          break;
      }
    }, (err: HttpErrorResponse) => {
      let res = err.error;

      if (res.success) {
        if (success) success(res);
      } else {
        if (fail) fail(res as ErrorResponse);
      }
    });
  }

  handleResponse(res: Response|HttpErrorResponse, success: (res: any) => void = null, fail: (res: ErrorResponse) => void = null) {
    if (res instanceof HttpErrorResponse) {
      if (res.error) {
        res = res.error;
      }
    }

    if ((res as Response)?.success) {
      if (success) success(res);
    } else {
      if (fail) fail(res as ErrorResponse);
      if ((res as ErrorResponse).error === 401) {
        this.authService.logout();
        this.router.navigate(['/connexion']);
      }
      else if ((res as ErrorResponse).error === 402) {
        this.router.navigate(['/conciergerie/home']);
      }
    }
  }

  getUrl(service: string): string {
    if (service[0] == '/') {
      return this.base_url + service;
    }

    return this.base_url + '/' + service;
  }

  logout(callback) {
    this.post('logout', {}, _ => {
      this.authService.logout();
      callback();
    }, _ => {
      this.authService.logout();
      callback();
    });
  }

}

export interface Response {
  success: boolean;
}

export interface ErrorResponse extends Response {
  error: number;
  message: string|null
}

export interface LoginResponse extends Response {
  token: string;
  lang: string;
  user: User;
}

export interface MeResponse extends Response {
  user: User;
}

export interface ConciergeriesResponse extends Response {
  list: Conciergerie[];
}

export interface ConciergerieResponse extends Response {
  conciergerie: Conciergerie;
  is_admin: boolean;
}
