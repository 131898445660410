import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private translate: TranslateService, private toastr: ToastrService) {

  }

  error(msg: string, title: string = 'error.error') {
    this.translate.get([msg, title]).subscribe((res: any) => {
      this.toastr.error(res[msg], res[title]);
    });
  }

  success(msg: string, title: string = 'success.success') {
    this.translate.get([msg, title]).subscribe((res: any) => {
      this.toastr.success(res[msg], res[title]);
    });
  }

}
