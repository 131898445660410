import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Directive, Input, Output, HostListener, NgModule } from '@angular/core';
import { saveAs } from 'file-saver';
import * as i2 from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
class FileSaverService {
  get isFileSaverSupported() {
    try {
      return !!new Blob();
    } catch (e) {
      return false;
    }
  }
  genType(fileName) {
    if (!fileName || fileName.lastIndexOf('.') === -1) {
      return 'text/plain';
    }
    const type = fileName.substr(fileName.lastIndexOf('.') + 1);
    switch (type) {
      case 'txt':
        return 'text/plain';
      case 'xml':
      case 'html':
        return `text/${type}`;
      case 'json':
        return 'octet/stream';
      default:
        return `application/${type}`;
    }
  }
  save(blob, fileName, filtType, option) {
    if (!blob) {
      throw new Error('Data argument should be a blob instance');
    }
    const file = new Blob([blob], {
      type: filtType || blob.type || this.genType(fileName)
    });
    saveAs(file, decodeURI(fileName || 'download'), option);
  }
  saveText(txt, fileName, option) {
    const blob = new Blob([txt]);
    this.save(blob, fileName, undefined, option);
  }
}
FileSaverService.ɵfac = function FileSaverService_Factory(ɵt) {
  return new (ɵt || FileSaverService)();
};
FileSaverService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FileSaverService,
  factory: FileSaverService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileSaverService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class FileSaverDirective {
  constructor(el, fss, httpClient) {
    this.el = el;
    this.fss = fss;
    this.httpClient = httpClient;
    this.method = 'GET';
    this.success = new EventEmitter();
    this.error = new EventEmitter();
    if (!fss.isFileSaverSupported) {
      el.nativeElement.classList.add(`filesaver__not-support`);
    }
  }
  getName(res) {
    return decodeURI(this.fileName || res.headers.get('filename') || res.headers.get('x-filename') || '');
  }
  _click() {
    if (!this.fss.isFileSaverSupported) {
      return;
    }
    let req = this.http;
    if (!req) {
      let params = new HttpParams();
      const query = this.query || {};
      // tslint:disable-next-line:forin
      for (const item in query) {
        params = params.set(item, query[item]);
      }
      req = this.httpClient.request(this.method, this.url, {
        observe: 'response',
        responseType: 'blob',
        headers: this.header,
        params
      });
    }
    this.setDisabled(true);
    req.subscribe(res => {
      if (res.status !== 200 || res.body.size <= 0) {
        this.error.emit(res);
        return;
      }
      this.fss.save(res.body, this.getName(res), undefined, this.fsOptions);
      this.success.emit(res);
    }, err => this.error.emit(err), () => this.setDisabled(false));
  }
  setDisabled(status) {
    const el = this.el.nativeElement;
    el.disabled = status;
    el.classList[status ? 'add' : 'remove'](`filesaver__disabled`);
  }
}
FileSaverDirective.ɵfac = function FileSaverDirective_Factory(ɵt) {
  return new (ɵt || FileSaverDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(FileSaverService), i0.ɵɵdirectiveInject(i2.HttpClient));
};
FileSaverDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FileSaverDirective,
  selectors: [["", "fileSaver", ""]],
  hostBindings: function FileSaverDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function FileSaverDirective_click_HostBindingHandler() {
        return ctx._click();
      });
    }
  },
  inputs: {
    method: "method",
    http: "http",
    query: "query",
    header: "header",
    url: "url",
    fileName: "fileName",
    fsOptions: "fsOptions"
  },
  outputs: {
    success: "success",
    error: "error"
  },
  exportAs: ["fileSaver"],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileSaverDirective, [{
    type: Directive,
    args: [{
      selector: '[fileSaver]',
      exportAs: 'fileSaver',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: FileSaverService
    }, {
      type: i2.HttpClient
    }];
  }, {
    method: [{
      type: Input
    }],
    http: [{
      type: Input
    }],
    query: [{
      type: Input
    }],
    header: [{
      type: Input
    }],
    url: [{
      type: Input
    }],
    fileName: [{
      type: Input
    }],
    fsOptions: [{
      type: Input
    }],
    success: [{
      type: Output
    }],
    error: [{
      type: Output
    }],
    _click: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class FileSaverModule {}
FileSaverModule.ɵfac = function FileSaverModule_Factory(ɵt) {
  return new (ɵt || FileSaverModule)();
};
FileSaverModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FileSaverModule
});
FileSaverModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileSaverModule, [{
    type: NgModule,
    args: [{
      imports: [FileSaverDirective],
      exports: [FileSaverDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FileSaverDirective, FileSaverModule, FileSaverService };
